import IPC from './IPC';
import StreamingElement from '../views/StreamingElement';
import Debugger from './Debugger';

type StreamerClientOptions = {
    webSocketEndpoint: string;
    loggedOut: boolean;
    activated: boolean;
    site: string;
};

class StreamerClient
{
    private static instance: StreamerClient;

    public static create(): StreamerClient
    {
        if (!StreamerClient.instance)
        {
            StreamerClient.instance = new StreamerClient();
        }
        return StreamerClient.instance;
    }

    private ipc: IPC;
    private options: StreamerClientOptions;
    private debugger: Debugger = Debugger.get();
    private elements: StreamingElement[] = [];

    private constructor() { }

    public setOptions(options: StreamerClientOptions): this
    {
        this.options = options;
        return this;
    }

    public init(): void
    {
        Debugger.get().logStreamerInfo('StreamerClient.init()');

        if (!this.options)
        {
            throw new Error('StreamerClient options not set');
        }

        const { webSocketEndpoint, loggedOut, activated, site } = this.options;

        if (this.ipc)
        {
            this.ipc.disconnect('Closing socket for reconnection');
        }

        this.resetElements();

        this.ipc = new IPC({ webSocketEndpoint, loggedOut, activated, site });

        document.querySelectorAll('[data-asc-symbol]').forEach((element: HTMLElement) =>
        {
            this.elements.push(new StreamingElement(element, this.ipc));
        });
    }

    private resetElements(): void
    {
        this.elements.forEach((element: StreamingElement) =>
        {
            element.destroy();
        });
        this.elements = [];
    }

    public getDebugger(): Debugger
    {
        return this.debugger;
    }
}

export default StreamerClient;