import FeedContent from './FeedContent';
import IPC from './IPC';
import IPCSubscriber from './IPCSubscriber';

export type FeedRequestStatus = 'started' | 'stopped' | 'idle';

class FeedRequest implements IPCSubscriber
{
    static CLIENT_ID: number = 0;

    static getNextClientID(): number
    {
        return ++FeedRequest.CLIENT_ID;
    }

    private clientID: number;
    private ipc: IPC;
    private request: string = '';
    private status: FeedRequestStatus = 'idle';

    constructor(websocketHandler: IPC)
    {
        this.clientID = FeedRequest.getNextClientID();
        this.ipc = websocketHandler;
    }

    public getClientID(): number
    {
        return this.clientID;
    }

    public getRequest(): string
    {
        return this.request;
    }

    public isStarted(): boolean
    {
        return this.status === 'started';
    }

    public isStopped(): boolean
    {
        return this.status === 'stopped';
    }

    public addSymbol(symbol: string): FeedRequest
    {
        this.request += '0' + symbol + '~';
        return this;
    }

    public addID(id: string): FeedRequest
    {
        this.request += '1' + id + '~';
        return this;
    }

    public addParam(param: string | number): this
    {
        this.request += '2' + param.toString(16) + '~';
        return this;
    }

    public addParams(params: string[] | number[]): FeedRequest
    {
        for (let param of params)
        {
            this.addParam(param);
        }
        return this;
    }

    public toString(): string
    {
        return `FeedRequest: id=${this.clientID}, request=${this.request}, status=${this.status}`;
    }

    public start(): void
    {
        this.ipc.subscribe(this);
        this.status = 'started';
    }

    public stop(): void
    {
        if (this.isStarted())
        {
            this.ipc.unsubscribe(this);
            this.status = 'stopped';
        }
    }

    public onData(data: FeedContent): void { }

    public onLoadingComplete(): void { }

    public onDisconnect(): void { }

    public onLogout(): void { }
}

export default FeedRequest;