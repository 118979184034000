import Debugger from '../lib/Debugger';
import { camelCase } from '../lib/Utils';
import ParentElement from './ParentElement';

abstract class BaseElement
{
    private internalID: number;
    private element: HTMLElement;
    private parent: ParentElement;

    private content: string;
    private styles: { [key: string]: string; } = {};
    private data: { [key: string]: string; } = {};

    // Drawing loop
    private dirtyContent: boolean = false;
    private dirtyStyles: boolean = false;
    private drawInterval: NodeJS.Timer;

    static INTERNAL_IDS: number = 0;

    constructor(element: HTMLElement)
    {
        this.internalID = BaseElement.INTERNAL_IDS++;

        Debugger.get().logStreamerInfo('Created element');

        this.element = element;

        this.setContent(this.element.innerText);

        this.drawInterval = setInterval(() => this.draw(), 100);
    }

    public getInternalID(): number
    {
        return this.internalID;
    }

    public getParent(): ParentElement
    {
        return this.parent;
    }

    public setParent(parent: ParentElement): this
    {
        this.parent = parent;
        return this;
    }

    public destroy(): void
    {
        let parent = this.getParent();
        if (parent)
        {
            parent.destroy();
        }

        this.element = null;
        this.dirtyContent = false;
        this.dirtyStyles = false;

        clearInterval(this.drawInterval);
    }

    private draw(): void
    {
        if (this.dirtyContent)
        {
            this.element.innerText = this.content;
            this.dirtyContent = false;
        }

        if (this.dirtyStyles)
        {
            for (let key in this.styles)
            {
                this.element.style[key] = this.styles[key];
            }
            this.dirtyStyles = false;
        }
    }

    public getData(key: string): string | null
    {
        key = camelCase(key);

        // Check if in memory
        if (typeof this.data[key] !== 'undefined')
        {
            return this.data[key];
        }
        else
        {
            // Obtain from DOM and store in memory
            if (this.element.dataset[key])
            {
                this.data[key] = this.element.dataset[key];
            }
            else
            {
                this.data[key] = null;
            }
        }

        return this.data[key];
    }

    public setContent(content: string): this
    {
        content = content.trim();

        if (content === '') return;
        if (content === this.content) return;

        this.content = content;
        this.dirtyContent = true;

        return this;
    }

    public getContent(): string
    {
        return this.element.innerText;
    }

    public setCssClasses(cssClasses: string[]): this
    {
        this.element.className = cssClasses.join(' ');
        return this;
    }

    public addClass(cssClass: string): this
    {
        this.element.classList.add(cssClass);
        return this;
    }

    public removeClass(cssClass: string): this
    {
        this.element.classList.remove(cssClass);
        return this;
    }

    public setStyles(styles: { [key: string]: string; }): this
    {
        for (let key in styles)
        {
            this.styles[key] = styles[key];
        }

        this.dirtyStyles = true;
        return this;
    }

    public setBackgroundColor(color: string): this
    {
        return this.setStyles({ backgroundColor: color });
    }

    public setTextColor(color: string): this
    {
        return this.setStyles({ color: color });
    }
}

export default BaseElement;