import FeedContentFlag from './FeedContentFlag';

export type FeedContentFlagAsJSON = {
    initialValue: boolean;
    current: 'up' | 'down' | 'no_change';
    update: 'up' | 'down' | 'no_change';
    period: 'up' | 'down' | 'no_change';
    highlight: boolean;
};

class FeedContent
{
    private id: number;
    private content: string;
    private flags: number;

    constructor(id: number, content: string, flags: number)
    {
        this.id = id;
        this.content = content;
        this.flags = flags;
    }

    public getID(): number
    {
        return this.id;
    }

    public getContents(): string
    {
        return this.content;
    }

    public getFlags(): number
    {
        return this.flags;
    }

    public getFlagsJSON(): FeedContentFlagAsJSON
    {
        let flags = {
            initialValue: false,
            current: null,
            update: null,
            period: null,
            highlight: false,
        };

        const contentAsFloat = parseFloat(this.content);
        if (!isNaN(contentAsFloat))
        {
            if (contentAsFloat > 0)
            {
                flags.current = 'up';
            }
            else if (contentAsFloat < 0)
            {
                flags.current = 'down';
            }
            else
            {
                flags.current = 'no_change';
            }
        }

        if (this.flags & FeedContentFlag.INITIAL_VALUE)
        {
            flags.initialValue = true;
        }

        if (this.flags & FeedContentFlag.UPDATE_UP)
        {
            flags.update = 'up';
        }
        else if (this.flags & FeedContentFlag.UPDATE_DOWN)
        {
            flags.update = 'down';
        }
        else if (this.flags & FeedContentFlag.UPDATE_NO_CHANGE)
        {
            flags.update = 'no_change';
        }

        if (this.flags & FeedContentFlag.PERIOD_UP)
        {
            flags.period = 'up';
        }
        else if (this.flags & FeedContentFlag.PERIOD_DOWN)
        {
            flags.period = 'down';
        }
        else if (this.flags & FeedContentFlag.PERIOD_NO_CHANGE)
        {
            flags.period = 'no_change';
        }

        if (this.flags & FeedContentFlag.HIGHLIGHT)
        {
            flags.highlight = true;
        }

        return flags;
    }

    public toString(): string
    {
        return `FeedContent: id=${this.id}, contents=${this.content}, flags=${this.flags}`;
    }
}


export default FeedContent;