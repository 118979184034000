import StreamerClientError from '../errors/StreamerClientError';

export default class FID
{
    public static fromString(fid: string): number
    {
        if (!fid)
        {
            throw new StreamerClientError('FID is empty', 'fid_empty');
        }

        fid = fid.toUpperCase();
        if (FID[fid])
        {
            return FID[fid];
        }

        throw new StreamerClientError(`Unknown FID: ${fid}`, 'fid_unknown');
    }

    static ORDERS_BID_TIME = 0x0200;
    static ORDERS_BID_SIZE = 0x0201;
    static ORDERS_BID_PRICE = 0x0202;
    static ORDERS_BID_CODE = 0x0203;
    static ORDERS_BID_LIST_COMMAND = 0x02ff;

    static ORDERS_OFFER_TIME = 0x0300;
    static ORDERS_OFFER_SIZE = 0x0301;
    static ORDERS_OFFER_PRICE = 0x0302;
    static ORDERS_OFFER_CODE = 0x0303;
    static ORDERS_OFFER_LIST_COMMAND = 0x03ff;

    static CHANGE = 0x0020;
    static CHANGE_PC = 0x0021;
    static CUR_PRICE = 0x0022;
    static BID_PRICE = 0x0023;
    static BID_SIZE = 0x0060;
    static OFFER_PRICE = 0x0024;
    static OFFER_SIZE = 0x0061;
    static MID_PRICE = 0x0025;
    static SPREAD = 0x0026;
    static SPREAD_PC = 0x0027;
    static OPEN_PRICE = 0x0028;
    static HIGH_PRICE = 0x0029;
    static LOW_PRICE = 0x002a;
    static CLOSE_PRICE = 0x002b;
    static LAST_CHANGE_TIME = 0x002c;
    static OPEN_CHANGE = 0x002d;
    static OPEN_CHANGE_PC = 0x002e;

    static VOLUME = 0x0030;
    static BUY_VOLUME = 0x0031;
    static SELL_VOLUME = 0x0032;
    static UNKNOWN_VOLUME = 0x0033;
    static BUY_SELL_UNKNOWN = 0x0034;
    static BUY_PC = 0x0035;
    static SELL_PC = 0x0036;
    static UNKNOWN_PC = 0x0037;
    static OPEN_INTEREST = 0x0070;

    static TIME = 0x00e0;
    static DELAYED = 0x00e2;

    static NEWS_COUNT_BB = 0x004a;
    static NEWS_COUNT_NEWS_REG = 0x004c;
    static NEWS_COUNT_NEWS_NON_REG = 0x004d;
    static NEWS_COUNT_NEWS_ALL = 0x004b;

    static PERIOD_1_PERIOD = 0x0400;
    static PERIOD_1_CUR_OPEN = 0x0490;
    static PERIOD_1_CUR_HIGH = 0x04a0;
    static PERIOD_1_CUR_LOW = 0x04b0;
    static PERIOD_1_CUR_CLOSE = 0x04c0;
    static PERIOD_1_TOTAL_VOLUME = 0x04d0;
    static PERIOD_1_BUY_VOLUME = 0x04e0;
    static PERIOD_1_SELL_VOLUME = 0x04f0;
    static PERIOD_1_UNKNOWN_VOLUME = 0x0500;

    static TRADE_PRICE_0 = 0x00a0;
    static TRADE_PRICE_1 = 0x00a1;
    static TRADE_PRICE_2 = 0x00a2;
    static TRADE_PRICE_3 = 0x00a3;
    static TRADE_PRICE_4 = 0x00a4;
    static TRADE_SIZE = 0x00a5;
    static TRADE_TIME = 0x00a6;
    static TRADE_TYPE = 0x00a7;
    static TRADE_HIGH = 0x00a8;
    static TRADE_LOW = 0x00a9;
    static NUM_TRADES = 0x00aa;
    static TRADE_EXCHANGE = 0x00b3;
    static TRADE_BUY_SELL = 0x00b4;

    static YEST_BID = 0x0050;
    static YEST_OFFER = 0x0051;
    static YEST_CLOSE = 0x0052;

    static TRADES_TIME = 0x0100;
    static TRADES_PRICE = 0x0101;
    static TRADES_SIZE = 0x0102;
    static TRADES_TYPE = 0x0103;
    static TRADES_EXCHANGE = 0x0104;
    static TRADES_BID = 0x0105;
    static TRADES_OFFER = 0x0106;
    static TRADES_BUY_SIZE = 0x0107;
    static TRADES_SELL_SIZE = 0x0108;
    static TRADES_UNKNOWN_SIZE = 0x0109;
    static TRADES_BUY_VOLUME = 0x010a;
    static TRADES_SELL_VOLUME = 0x010b;
    static TRADES_UNKNOWN_VOLUME = 0x010c;
    static TRADES_NUMBER = 0x010d;
    static TRADES_BUY_SELL_UNKNOWN = 0x0110;
    static TRADES_FLAGS = 0x0111;
    static V1_TRADES_CODE = 0x0116;
    static TRADES_LIST_COMMAND = 0x01ff;

    /* *** FLOW Codes *** */

    // Insert Codes
    static FLOW_I_P1_BID = 0x0610;
    static FLOW_I_P1_OFFER = 0x0611;
    static FLOW_I_P5_BID = 0x0612;
    static FLOW_I_P5_OFFER = 0x0613;
    static FLOW_I_P10_BID = 0x0614;
    static FLOW_I_P10_OFFER = 0x0615;
    static FLOW_I_P15_BID = 0x0616;
    static FLOW_I_P15_OFFER = 0x0617;
    static FLOW_I_P30_BID = 0x0618;
    static FLOW_I_P30_OFFER = 0x0619;
    static FLOW_I_P60_BID = 0x061a;
    static FLOW_I_P60_OFFER = 0x061b;

    // Delete Codes
    static FLOW_D_P1_BID = 0x061c;
    static FLOW_D_P1_OFFER = 0x061d;
    static FLOW_D_P5_BID = 0x061e;
    static FLOW_D_P5_OFFER = 0x061f;
    static FLOW_D_P10_BID = 0x0620;
    static FLOW_D_P10_OFFER = 0x0621;
    static FLOW_D_P15_BID = 0x0622;
    static FLOW_D_P15_OFFER = 0x0623;
    static FLOW_D_P30_BID = 0x0624;
    static FLOW_D_P30_OFFER = 0x0625;
    static FLOW_D_P60_BID = 0x0626;
    static FLOW_D_P60_OFFER = 0x0627;

    // Price Up Codes
    static FLOW_PU_P1_BID = 0x0628;
    static FLOW_PU_P1_OFFER = 0x0629;
    static FLOW_PU_P5_BID = 0x062a;
    static FLOW_PU_P5_OFFER = 0x062b;
    static FLOW_PU_P10_BID = 0x062c;
    static FLOW_PU_P10_OFFER = 0x062d;
    static FLOW_PU_P15_BID = 0x062e;
    static FLOW_PU_P15_OFFER = 0x062f;
    static FLOW_PU_P30_BID = 0x0630;
    static FLOW_PU_P30_OFFER = 0x0631;
    static FLOW_PU_P60_BID = 0x0632;
    static FLOW_PU_P60_OFFER = 0x0633;

    // Price Down Codes
    static FLOW_PD_P1_BID = 0x0634;
    static FLOW_PD_P1_OFFER = 0x0635;
    static FLOW_PD_P5_BID = 0x0636;
    static FLOW_PD_P5_OFFER = 0x0637;
    static FLOW_PD_P10_BID = 0x0638;
    static FLOW_PD_P10_OFFER = 0x0639;
    static FLOW_PD_P15_BID = 0x063a;
    static FLOW_PD_P15_OFFER = 0x063b;
    static FLOW_PD_P30_BID = 0x063c;
    static FLOW_PD_P30_OFFER = 0x063d;
    static FLOW_PD_P60_BID = 0x063e;
    static FLOW_PD_P60_OFFER = 0x0670;

    // Size Up Codes
    static FLOW_SU_P1_BID = 0x0671;
    static FLOW_SU_P1_OFFER = 0x0672;
    static FLOW_SU_P5_BID = 0x0673;
    static FLOW_SU_P5_OFFER = 0x0674;
    static FLOW_SU_P10_BID = 0x0675;
    static FLOW_SU_P10_OFFER = 0x0676;
    static FLOW_SU_P15_BID = 0x0677;
    static FLOW_SU_P15_OFFER = 0x0678;
    static FLOW_SU_P30_BID = 0x0679;
    static FLOW_SU_P30_OFFER = 0x067a;
    static FLOW_SU_P60_BID = 0x067b;
    static FLOW_SU_P60_OFFER = 0x067c;

    // Size Down Codes
    static FLOW_SD_P1_BID = 0x067d;
    static FLOW_SD_P1_OFFER = 0x067e;
    static FLOW_SD_P5_BID = 0x067f;
    static FLOW_SD_P5_OFFER = 0x0680;
    static FLOW_SD_P10_BID = 0x0681;
    static FLOW_SD_P10_OFFER = 0x0682;
    static FLOW_SD_P15_BID = 0x0683;
    static FLOW_SD_P15_OFFER = 0x0684;
    static FLOW_SD_P30_BID = 0x0685;
    static FLOW_SD_P30_OFFER = 0x0686;
    static FLOW_SD_P60_BID = 0x0687;
    static FLOW_SD_P60_OFFER = 0x0688;

    /* Rate Volume - May 2018 */
    static RATE_VOLUME = 0x0689;
    static PERIOD_1_RATE_VOLUME = 0x0690;

    /* AUCTION */
    static LSE_REFERENCE_PRICE = 0x71;
    static LSE_DAILY_VWAP = 0x72;
    static LSE_UNCROSSING_PRICE = 0x73;
    static LSE_UNCROSSING_VOLUME = 0x74;
    static LSE_AUCTION_TIME = 0x75;

    /* FLAGS */
    static FLAGS = 0x40;
    static LONG_FLAGS = 0x41;
}