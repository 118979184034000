import AuthManager from './AuthManager';
import FeedRequest from './FeedRequest';
import FeedContent from './FeedContent';
import FeedContentFlag from './FeedContentFlag';
import IPCSubscriber from './IPCSubscriber';
import Debugger from './Debugger';

type IPCSubscriberStatus = 'loading' | 'completed';

type IPCOptions = {
    webSocketEndpoint: string;
    loggedOut: boolean;
    activated: boolean;
    site: string;
};

class IPC
{
    private webSocketEndpoint: string;
    private loggedOut: boolean;
    private activated: boolean;
    private tag: string;
    private webSocket: WebSocket;
    private connected: boolean = false;

    // Subscriptions to the IPC
    private currentClient: FeedRequest | null;
    private subscribers: FeedRequest[] = [];
    private subscribersQueue: FeedRequest[] = [];
    private subscriberStatus: { [key: number]: IPCSubscriberStatus; } = [];

    // Reconnection Mechanism
    private attemptReconnection: boolean = true;
    private connectionAttempts: number = 0;
    private connectionMaxAttempts: number = 2;
    private reconnectionTimeout: number = 125;
    private reconnectionMaxTime: number = 180000;
    private lastPingTimestamp: number;

    //current site
    private site: string;

    constructor({ webSocketEndpoint, loggedOut, activated, site }: IPCOptions)
    {
        this.webSocketEndpoint = webSocketEndpoint;
        this.loggedOut = loggedOut;
        this.activated = activated;
        this.site = site;
        this.tag = this.generateIPCTag();
        this.connectWebSocket();
    }

    private generateIPCTag(): string
    {
        let temp = [];
        for (let i = 0; i < 8; ++i)
        {
            temp.push(Math.floor(Math.random() * 11));
        }
        return temp.join('');
    }

    public getIPCTag(): string
    {
        return this.tag;
    }

    private connectWebSocket(): void
    {
        this.webSocket = new WebSocket(this.webSocketEndpoint);

        this.webSocket.onopen = (event: Event) =>
        {
            this.handleWebSocketOpen(event);
        };

        this.webSocket.onmessage = (event: MessageEvent) =>
        {
            this.handleWebSocketMessage(event);
        };

        this.webSocket.onerror = (event: Event) =>
        {
            this.handleWebSocketError(event);
        };

        this.webSocket.onclose = (event: CloseEvent) =>
        {
            this.handleWebSocketClose(event);
        };
    }

    public isConnected(): boolean
    {
        return this.connected;
    }

    public disconnect(closeReason: string): void
    {
        this.attemptReconnection = false;
        this.webSocket.close(1000, closeReason);
    }

    private resetConnectionTimeout(): void
    {
        this.connectionAttempts = 0;
        this.reconnectionTimeout = 500;
    }

    private handleReconnection(): void
    {
        this.reconnectionTimeout = this.reconnectionTimeout * 2;
        this.connectionAttempts++;
        setTimeout(() =>
        {
            this.connectWebSocket();
        }, this.reconnectionTimeout);
    }

    private handleWebSocketOpen(event: Event): void
    {
        this.connected = true;
        this.resetConnectionTimeout();
    }

    private handleWebSocketMessage(event: MessageEvent): void
    {
        const message = event.data;

        // Ping message
        if (message === '#')
        {
            this.lastPingTimestamp = Date.now();
            return;
        }

        // Message starts with streamer hostname
        if (message.startsWith('Streamer'))
        {
            Debugger.get().logStreamerInfo(`Connected to: ${message}`);
            return;
        }

        // Information message
        if (message.startsWith('I'))
        {
            Debugger.get().logStreamerInfo(message.substr(1));
            return;
        }

        // Connect
        if (message === 'connect')
        {
            const data = {
                type: 'stream_request',
                ipc_tag: this.getIPCTag(),
            };
            this.send(data);

            // Subscribe to all queued subscriptions
            this.subscribersQueue.forEach((subscription: FeedRequest) =>
            {
                this.subscribe(subscription);
            });
            this.subscribersQueue = [];
            return;
        }

        // Swap currently active client
        if (message[0] === '$')
        {
            const [ipcTag, clientID] = message.substr(1).split(',');

            if (this.subscribers[clientID] === undefined)
            {
                this.currentClient = null;
                return;
            }

            this.currentClient = this.subscribers[clientID];
            return;
        }

        // Handle special messages (logout, etc.)
        if (message[0] === '+')
        {
            const [code, data] = message.substr(1).split(':');
            if (code === '6')
            {
                if (!this.activated) return;

                this.handleLogout();
                return;
            }

            console.warn(`Unknown (+) message: ${message}`);
            return;
        }

        // Handle data messages
        if (this.currentClient instanceof FeedRequest)
        {
            const command = message[0];

            switch (command)
            {
                // Data received
                case '{':
                    const [idReceived, content, flagsReceived] = message.substr(1).split('~');

                    const contentID = parseInt(idReceived, 10);

                    let flags = parseInt(flagsReceived, 10);
                    if (this.subscriberStatus[this.currentClient.getClientID()] !== 'completed')
                    {
                        flags += FeedContentFlag.INITIAL_VALUE;
                    }

                    const feedContent = new FeedContent(contentID, content, flags);
                    this.currentClient.onData(feedContent);
                    break;

                // Loading complete
                case 'p':
                    const percentage = message.substr(1);
                    if (percentage === '100')
                    {
                        this.subscriberStatus[this.currentClient.getClientID()] = 'completed';
                        this.currentClient.onLoadingComplete();
                    }
                    break;
            }
        }
    }

    public subscribe(feed: FeedRequest): boolean
    {
        if (feed instanceof FeedRequest)
        {
            let { username, SID } = AuthManager.getInstance().toJSON(this.loggedOut);

            if (this.isConnected())
            {
                var clientID = feed.getClientID();
                var data = {
                    type: 'subscribe',
                    ipc_tag: this.getIPCTag(),
                    client_id: clientID,
                    user: username,
                    sid: SID,
                    page_key: this.getIPCTag(),
                    app: 'streamerClient',
                    request: feed.getRequest()
                };
                this.send(data);

                // Initialize subscriber and its status
                this.subscribers[clientID] = feed;
                this.subscriberStatus[clientID] = 'loading';
                return true;
            }
            else
            {
                this.subscribersQueue.push(feed);
            }
        }
        return false;
    }

    public unsubscribe(feed: FeedRequest): boolean
    {
        if (feed instanceof FeedRequest)
        {
            if (feed.isStarted())
            {
                var clientID = feed.getClientID();
                var data = {
                    type: 'unsubscribe',
                    ipc_tag: this.getIPCTag(),
                    client_id: clientID
                };
                this.send(data);

                // Remove subscriber from lists
                delete this.subscribers[clientID];
                delete this.subscriberStatus[clientID];

                return true;
            }
        }
        return false;
    }

    private send(data: any): void
    {
        this.webSocket.send(JSON.stringify(data));
    }

    private handleWebSocketError(event: Event): void
    {
        console.error('WebsocketHandler.handleWebSocketError', event);
    }

    private handleWebSocketClose(event: CloseEvent): void
    {
        if (this.connectionAttempts >= this.connectionMaxAttempts)
        {
            this.handleDisconnect();
            return;
        }

        if (this.reconnectionTimeout >= this.reconnectionMaxTime)
        {
            this.handleDisconnect();
            return;
        }

        if (this.attemptReconnection)
        {
            setTimeout(() =>
            {
                this.handleReconnection();
            }, 2000); // Throtle reconnection attempts by 2 seconds
        }
    }

    private handleDisconnect(): void
    {
        this.connected = false;
        this.attemptReconnection = false;
    }

    private handleLogout(): void
    {
        if (this.site === 'ih')
        {
            this.subscribers.forEach((subscriber: IPCSubscriber) =>
            {
                subscriber.onLogout();
            });
        }
        else
        {
            if (this.loggedOut) return;

            const base64Url = window.btoa(window.location.href);

            window.location.href = `/common/account/reconnect?redirect_url=${base64Url}`;
        }
    }
}

export default IPC;