import BaseElement from './BaseElement';

class ParentElement extends BaseElement
{
    constructor(element: HTMLElement)
    {
        super(element);
    }

    public setContent(content: string): this
    {
        // Parents do not update content!
        return this;
    }
}

export default ParentElement;