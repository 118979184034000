import Debugger from '../lib/Debugger';
import FeedContent from '../lib/FeedContent';
import FeedRequest from '../lib/FeedRequest';
import FID from '../lib/FID';
import IPC from '../lib/IPC';
import BaseElement from './BaseElement';
import ParentElement from './ParentElement';

class StreamingElement extends BaseElement
{
    private feedRequest: FeedRequest;

    private symbol: string;
    private param: string;
    private fid: number;

    private renderFunction: string;

    constructor(element: HTMLElement, ipc: IPC)
    {
        super(element);

        this.setParent(new ParentElement(element.parentElement));

        this.symbol = this.getData('asc-symbol');
        this.param = this.getData('asc-param');
        this.renderFunction = this.getData('asc-render');

        this.fid = FID.fromString(this.param);
        this.feedRequest = new FeedRequest(ipc);
        this.feedRequest.addSymbol(this.symbol);
        this.feedRequest.addParam(this.fid);

        this.feedRequest.onData = (feedContent: FeedContent) =>
        {
            this.handleFeedContent(feedContent);
        };

        this.feedRequest.onLoadingComplete = () => { };

        this.feedRequest.start();
    }

    public getSymbol(): string
    {
        return this.symbol;
    }

    public getFID(): number
    {
        return this.fid;
    }

    public getParam(): string
    {
        return this.param;
    }

    private handleFeedContent(feedContent: FeedContent): void
    {
        if (this.renderFunction)
        {
            if (this.renderFunction.indexOf('.') !== -1)
            {
                let [objectName, functionName] = this.renderFunction.split('.');
                if (typeof (globalThis as any)[objectName][functionName] === 'function')
                {
                    (globalThis as any)[objectName][functionName](this, feedContent.getContents(), feedContent.getFlagsJSON());
                    return;
                }
            }
            else
            {
                if (typeof (globalThis as any)[this.renderFunction] === 'function')
                {
                    (globalThis as any)[this.renderFunction](this, feedContent.getContents(), feedContent.getFlagsJSON());
                    return;
                }
            }

            Debugger.get().logStreamerWarning(`Render function ${this.renderFunction} does not exist`);
        }

        this.setContent(feedContent.getContents());
    }
}

export default StreamingElement;