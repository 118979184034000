class Debugger
{
    private static instance: Debugger;

    public static get(): Debugger
    {
        if (!Debugger.instance)
        {
            Debugger.instance = new Debugger();
        }
        return Debugger.instance;
    }

    private constructor() { }

    /* *** Info Messages *** */

    private streamerInfoMessages: { [key: string]: number; } = {};

    public logStreamerInfo(message: string): void
    {
        if (!this.streamerInfoMessages[message])
        {
            this.streamerInfoMessages[message] = 0;
        }
        this.streamerInfoMessages[message]++;
    }

    /* *** Warning Messages *** */

    private streamerWarningMessages: { [key: string]: number; } = {};

    public logStreamerWarning(message: string): void
    {
        if (!this.streamerWarningMessages[message])
        {
            this.streamerWarningMessages[message] = 0;
        }
        this.streamerWarningMessages[message]++;
    }

    public getStreamerInfoMessages(): { [key: string]: number; }
    {
        return this.streamerInfoMessages;
    }

    public display(): void
    {
        console.log('%c*** ADVFN Streamer Client - DEBUGGER ***', 'background: black; color: yellow; font-size: 18px; margin-left: 10%');

        console.log('%c*** Streamer Info Messages: ***', 'color: darkblue; background: lightblue; font-weight: bold;');
        for (const message in this.streamerInfoMessages)
        {
            const count = this.streamerInfoMessages[message];
            console.log(`[INFO] ${message} (${count})`);
        }
        console.log('%c*** Streamer Warning Messages: ***', 'color: darkorange; background: lightyellow; font-weight: bold;');
        for (const message in this.streamerWarningMessages)
        {
            const count = this.streamerWarningMessages[message];
            console.log(`[WARN] ${message} (${count})`);
        }
        console.log('%c*** ADVFN Streamer Client - DEBUGGER ***', 'background: black; color: yellow; font-size: 18px; margin-left: 10%');
    }
}

export default Debugger;