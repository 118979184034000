import Cookies = require('js-cookie');

class AuthManager
{
    private static COOKIE_KEY_USERNAME = 'username';
    private static COOKIE_KEY_SID = 'SID';

    private static COOKIE_KEY_USERNAME_LO = 'username-lo';
    private static COOKIE_KEY_SID_LO = 'SID-lo';

    private static instance: AuthManager;

    public static getInstance(): AuthManager
    {
        if (AuthManager.instance === undefined)
        {
            AuthManager.instance = new AuthManager();
        }
        return AuthManager.instance;
    }

    private username: string;
    private SID: string;

    private username_lo: string;
    private SID_lo: string;
    private constructor()
    {
        this.username = Cookies.get(AuthManager.COOKIE_KEY_USERNAME);

        if (this.username !== undefined)
        {
            this.username = this.cleanUsername(this.username);
        }

        this.SID = Cookies.get(AuthManager.COOKIE_KEY_SID);

        this.username_lo = Cookies.get(AuthManager.COOKIE_KEY_USERNAME_LO);

        if (this.username_lo !== undefined)
        {
            this.username_lo = this.cleanUsername(this.username_lo);
        }

        this.SID_lo = Cookies.get(AuthManager.COOKIE_KEY_SID_LO);
    }

    private cleanUsername(username: string): string
    {
        // We need to replace all '+' with ' ' for auth
        return username.replace(/\+/g, ' ');
    }

    public getUsername(): string
    {
        return this.username;
    }

    public getSID(): string
    {
        return this.SID;
    }

    public toJSON(loggedOut: boolean): AuthManagerManagerObject
    {
        return {
            username: loggedOut ? this.username_lo : this.username,
            SID: loggedOut ? this.SID_lo : this.SID
        };
    }
}

export type AuthManagerManagerObject = {
    username: string;
    SID: string;
};

export default AuthManager;