class FeedContentFlag
{
    static UPDATE_UP: number = (1 << 0);
    static UPDATE_DOWN: number = (1 << 1);
    static UPDATE_NO_CHANGE: number = (1 << 2);
    static DELETED: number = (1 << 3);
    static HIGHLIGHT: number = (1 << 4);
    static PERIOD_UP: number = (1 << 5);
    static PERIOD_DOWN: number = (1 << 6);
    static PERIOD_NO_CHANGE: number = (1 << 7);
    static INITIAL_VALUE: number = (1 << 27);
    static KEEP_CONTENTS: number = (1 << 28);
    static LIST_INSERT: number = (1 << 29);
    static LIST_DELETE: number = (1 << 30);
}

export type FeedContentFlagUpdate = 'up' | 'down' | 'no_change';
export type FeedContentFlagPeriod = 'up' | 'down' | 'no_change';
export type FeedContentHighlight = 'highlight';

export default FeedContentFlag;