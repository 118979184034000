class StreamerClientError extends Error
{
    private code: StreamerClientErrorCode;

    constructor(message: string, code: StreamerClientErrorCode = 'unknown')
    {
        super(`${message} [${code}]`);

        this.name = 'StreamerClientError';
        this.code = code;

        Object.setPrototypeOf(this, StreamerClientError.prototype);
    }

    public getCode(): StreamerClientErrorCode
    {
        return this.code;
    }
}

type StreamerClientErrorCode = 'unknown' | string;

export default StreamerClientError;